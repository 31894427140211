<template>
    <v-container>
        <h3>{{ $t("message.title-create-shopInfo") }}</h3>
        <v-col class="pr-2 pb-2" cols="12">
            <form @submit.prevent="submitShopInfo()">
                <v-col
                    cols="12" md="3" xs="6" 
                >
                    <span class="">{{ $t("message.shop") }}</span>
                    <v-autocomplete
                    v-model="formShopInfo.shop_id"
                    @change="filterShop(formShopInfo.shop_id)"
                    class="p-0 mt-1"
                    :items="shopList"
                    item-text="store_name"
                    item-value="id"
                    outlined
                    ></v-autocomplete>
                </v-col>
                <v-row>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-distributor") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formShopInfo.seller"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-Responsible-person") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formShopInfo.pic"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-location") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formShopInfo.location"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-contact-info") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formShopInfo.contact"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-email-address") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formShopInfo.email"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-selling-price") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formShopInfo.price"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-fee") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formShopInfo.fee"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-payment-method") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formShopInfo.payment_method"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-delivery-time") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formShopInfo.receive_product"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-return_and_cancel") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formShopInfo.return_and_cancel"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                </v-row>
                <div class="d-flex flex-row-reverse mt-3 me-0" tile>
                    <div class="m-2">
                        <v-btn type="submit" color="primary" @click.prevent="submitShopInfo()">
                        {{ $t("message.submit-button") }}
                        </v-btn>
                    </div>
                    <div>
                        <v-btn color="grey" class="m-2" @click="$router.go(-1)">
                        {{ $t("message.back-button") }}
                        </v-btn>
                    </div>
                </div>
            </form>
        </v-col>
    </v-container>
</template>

<script>
export default {
    name: "CreateShopInfo",
    data() {
        return {
            formShopInfo: {
                shop_id: "",
                seller: "",
                pic: "",
                location: "",
                contact: "",
                email: "",
                price: "",
                fee: "",
                payment_method: "",
                receive_product: "",
                return_and_cancel: ""
            },
            
        }
    },
    computed: {
        shopList() {
            return this.$store.state.shop_module.shopAllResult
        },
    },
    methods: {
        async fetchShopList() {
            await this.$store.dispatch("shop_module/fetchShopAll");
            let shopId = this.$store.state.shop_module.shopAllResult
            
            this.formShopInfo.shop_id = shopId[0].id
            await this.$store.dispatch("shop_module/fetchShopInfo", this.formShopInfo.shop_id)

            let shopInfoDetail = await this.$store.state.shop_module.resultShopInfo
            if(shopInfoDetail.length == 0) {
                this.formShopInfo.seller = ""
                this.formShopInfo.pic = ""
                this.formShopInfo.location = ""
                this.formShopInfo.contact = ""
                this.formShopInfo.email = ""
                this.formShopInfo.price = ""
                this.formShopInfo.fee = ""
                this.formShopInfo.payment_method = ""
                this.formShopInfo.receive_product = ""
                this.formShopInfo.return_and_cancel = ""
            } else {
                this.formShopInfo.seller = shopInfoDetail.distributor
                this.formShopInfo.pic = shopInfoDetail.pic
                this.formShopInfo.location = shopInfoDetail.location
                this.formShopInfo.contact = shopInfoDetail.contact
                this.formShopInfo.email = shopInfoDetail.public_email
                this.formShopInfo.price = shopInfoDetail.seeling_price
                this.formShopInfo.fee = shopInfoDetail.fee
                this.formShopInfo.payment_method = shopInfoDetail.payment_method
                this.formShopInfo.receive_product = shopInfoDetail.delivery_time
                this.formShopInfo.return_and_cancel = shopInfoDetail.correspondence
            }
            
        },
        async filterShop(id) {
            await this.$store.dispatch("shop_module/fetchShopInfo", id)
            
            let shopInfoDetail = await this.$store.state.shop_module.resultShopInfo
            if(shopInfoDetail.length == 0) {
                this.formShopInfo.seller = ""
                this.formShopInfo.pic = ""
                this.formShopInfo.location = ""
                this.formShopInfo.contact = ""
                this.formShopInfo.email = ""
                this.formShopInfo.price = ""
                this.formShopInfo.fee = ""
                this.formShopInfo.payment_method = ""
                this.formShopInfo.receive_product = ""
                this.formShopInfo.return_and_cancel = ""
            } else {
                this.formShopInfo.seller = shopInfoDetail.distributor
                this.formShopInfo.pic = shopInfoDetail.pic
                this.formShopInfo.location = shopInfoDetail.location
                this.formShopInfo.contact = shopInfoDetail.contact
                this.formShopInfo.email = shopInfoDetail.public_email
                this.formShopInfo.price = shopInfoDetail.seeling_price
                this.formShopInfo.fee = shopInfoDetail.fee
                this.formShopInfo.payment_method = shopInfoDetail.payment_method
                this.formShopInfo.receive_product = shopInfoDetail.delivery_time
                this.formShopInfo.return_and_cancel = shopInfoDetail.correspondence
            }
        },
        submitShopInfo() {
            this.$store.dispatch("shop_module/createShopInfo", this.formShopInfo)

            this.formShopInfo.shop_id = "",
            this.formShopInfo.seller = "",
            this.formShopInfo.pic = "",
            this.formShopInfo.location = "",
            this.formShopInfo.contact = "",
            this.formShopInfo.email = "",
            this.formShopInfo.price = "",
            this.formShopInfo.fee = "",
            this.formShopInfo.payment_method = "",
            this.formShopInfo.receive_product = "",
            this.formShopInfo.return_and_cancel = ""
        }
    },
    created() {
        this.fetchShopList()
    }
}
</script>

<style>
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;

}
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 3px !important;
}
</style>